import React from "react";
import processTestObj from "../TestObjFormatter";
import RenderTestDetails from "./RenderTestDetails"
import { Grid } from "@material-ui/core"
import { connect } from "react-redux";
import ContainerImages from "./ContainerImages"
import ViewImages from "../../components/viewImages"

const OverviewDetails = props => {
    const test = props.test;
    const formattedTest = processTestObj(test, props.test_details_categories['Overview Details'], props.fieldTypes);
    const title = "Overview Details"
    const fieldKeys = Object.keys(props.test_details_categories['Overview Details'])

    return (
        <Grid container spacing={1} justify={"space-around"}>
            <Grid item xs={7}>
                <RenderTestDetails obj={formattedTest} classes={props.classes} title={"Overview Details"} />
            </Grid>
            <Grid item xs={4}>
                <ViewImages images={props.test.Containers} />
            </Grid>
        </Grid>

    )
};

const mapPropsToState = state => ({
    test_details_categories: state.test_details_categories,
    fieldTypes: state.test_field_types,
})
export default connect(mapPropsToState, {})(OverviewDetails);
