import React, { useEffect, useState } from 'react';
import clsx from 'clsx'
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import SendIcon from "@material-ui/icons/SendSharp";
import Icon from '@material-ui/core/Icon';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OneIcon from "@material-ui/icons/Filter1TwoTone";
import TwoIcon from "@material-ui/icons/Filter2TwoTone";
import ThreeIcon from "@material-ui/icons/Filter3TwoTone";
import FourIcon from "@material-ui/icons/Filter4TwoTone";
import FiveIcon from "@material-ui/icons/Filter5TwoTone";
import SixIcon from "@material-ui/icons/Filter6TwoTone"
import SevenIcon from "@material-ui/icons/Filter7TwoTone"
import RenderSideMenu from "./RenderSideMenu"
import {
    addDraft,
    toggleNotification,
    notificationMessage,
    fieldTypes,
} from "actions/TestActions";
import { postDraft, getUserLabs } from "http_calls/userHttp";
import NewSynonymsComponent from "./create_new_test_components/NewSynonymsComponent";
import NotificationSnackbar from "./Notification";
import RenderTestDetails from "test_components/RenderTestDetails";
import Grid from "@material-ui/core/Grid";
import NewTestSubmitButton from "./NewTestSubmitButton"
import SelectContainerImages from "./SelectContainerImages";
import CollectionIcon from "@material-ui/icons/Collections";
import OverviewDetails from "./test_components/OverviewDetails"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "whitesmoke"
        // display: 'flex',
    },
    container: {
        padding: theme.spacing(1)
        // backgroundImage:"url('/background_lab.jpg')"
    },
    drawer: {
        width: "300px",
        flexShrink: 0
    },
    drawerPaper: {
        width: "300px"
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        // padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
    },
    successColor: {
        backgroundColor: theme.palette.success["800"],
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

const Icons = [<OneIcon />, <TwoIcon />, <ThreeIcon />, <FourIcon />, <FiveIcon />, <SixIcon />, <SevenIcon />]


function RenderTestWithSideMenu(props) {
    const [category, setCategory] = useState("Overview Details")
    const [formData, setFormData] = useState(props.draft)
    const [viewSynonyms, setViewSynonyms] = useState(false)
    const [viewContainers, setViewContainers] = useState(false)
    const [containers, setContainers] = useState([])
    const classes = useStyles();

    useEffect(() => {
        if (category === "Overview Details" && props.test_field_types.Lab.defaultOptions.length < 1) {
            getUserLabs().then(res => {
                let _fieldTypes = { ...props.test_field_types }
                _fieldTypes.Lab.defaultOptions = [...res.data.labs]
                props.fieldTypes(_fieldTypes)
            }).catch(err => console.error(err))
        }
    }, [category,])

    const SwitchCategory = (text) => {
        if (viewSynonyms) {
            setViewSynonyms(false)
        } else if (viewContainers) {
            setViewContainers(false)
        }
        setCategory(text);
    }

    function changeTestContainers(containers) {
        let _formData = { ...formData }
        _formData.Containers = [...containers]
        setFormData(_formData)
        AddNewTestDraft(props.user.UserName, props.draftName, _formData)

    }

    const handleAddSynonym = (synonym) => {
        let _formData = { ...formData }
        if (synonym.SYNONYM_NAME.split(",").length > 0) {
            synonym.SYNONYM_NAME.split(",").forEach((syn_name) => {
                let _synonym = {}
                _synonym["SYNONYM_NAME"] = syn_name
                _synonym["PUBLISH"] = synonym.PUBLISH
                _synonym["MNEMONIC"] = synonym.MNEMONIC
                _formData.Synonyms.push(_synonym)
            })
        }

        setFormData(_formData)
        AddNewTestDraft(props.user.UserName, props.draftName, _formData)
    }
    const handleUpdateSynonym = (newSyn, oldSynName) => {
        let _formData = { ...formData }
        let _synonyms = [..._formData.Synonyms.filter((syn) => {
            return syn.SYNONYM_NAME !== oldSynName
        })]
        _synonyms.push(newSyn)
        _formData.Synonyms = _synonyms
        setFormData(_formData)
        AddNewTestDraft(props.user.UserName, props.draftName, _formData)
    }
    const handleRemoveSynonym = (delSynName) => {
        let _formData = { ...formData }
        let _synonyms = _formData.Synonyms.filter((syn, index) => {
            return syn.SYNONYM_NAME !== delSynName
        })
        _formData.Synonyms = _synonyms
        setFormData(_formData)
        AddNewTestDraft(props.user.UserName, props.draftName, _formData)
    }

    const switchToSynonyms = () => {
        setViewContainers(false)
        setCategory("Synonyms")
        setViewSynonyms(true)
    }

    const switchToContainers = () => {
        setViewSynonyms(false)
        setCategory("Containers")
        setViewContainers(true)
    }

    const AddNewTestDraft = (username, draftname, data) => {
       data.NyStateApproved =  data.NyStateApproved === true ? "true":"false";
        postDraft(username, data, draftname).then(res => {
            props.toggleNotification(true);
            props.notificationMessage(res.Msg);
        });
    }


    const synonymsOrRenderTestDetails = () => {
        if (viewSynonyms) {
            return <NewSynonymsComponent
                test={formData}
                addSynonym={handleAddSynonym}
                removeSynoynm={handleRemoveSynonym}
                updateSynonym={handleUpdateSynonym}
            />
        }
        if (viewContainers) {
            return <SelectContainerImages testContainers={formData.Containers ? formData.Containers : []} changeContainers={changeTestContainers} />
        }
        // if (category !== "Overview Details") {
        return <RenderTestDetails
            obj={props.test_details_categories[category]}
            title={" Enter " + category}
            editorSelector={category.split(" ").join("")}
            inputMode={true}
            draftName={props.draftName}
            addNewTestDraft={AddNewTestDraft}
            formData={formData}
            setFormData={setFormData}
        />
        // }
        // return <span />
    }
    return (
        <section className={classes.root}>
            <Typography variant="h3" gutterBottom>
                <center>{props.draftName?props.draftName:props.draft.draftName }</center>
            </Typography>
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                <Grid item xs={1}>
                    <NewTestSubmitButton
                        classes={classes}
                        formData={formData}
                        variant={"contained"}
                        test_details={props.draft}
                        color={"primary"}
                        checkChanges={false}
                        {...props}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" className={clsx(classes.button, classes.successColor)} onClick={() => { AddNewTestDraft(props.user.UserName, props.draftName?props.draftName:props.draft.draftName, formData) }} >
                        <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                        Save Draft
                    </Button>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="flex-start">
                <Grid item xs={3}>
                    <div className={classes.toolbar} />
                    <Divider />
                    <List>
                        {Object.keys(props.test_details_categories).filter(text => text !== "All").map((text, index) => (
                            <RenderSideMenu text={text} index={index} icon={Icons[index]} onClick={() => SwitchCategory(text)} selected={category === text ? true : false} />

                        ))}
                        <ListItem button key={"Synonyms"} onClick={switchToSynonyms} selected={category === "Synonyms" ? true : false}>
                            <ListItemIcon>
                                {Icons[Object.keys(props.test_details_categories).length - 1]}
                            </ListItemIcon>
                            <ListItemText primary={"Synonyms"} />
                        </ListItem>
                        <ListItem button key={"Containers"} onClick={switchToContainers} selected={category === "Containers" ? true : false}>
                            <ListItemIcon>
                                <CollectionIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Containers"} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={9}>
                    <section className={classes.container}>
                        <main className={classes.content}>
                            {synonymsOrRenderTestDetails()}
                        </main>
                    </section>
                </Grid>
            </Grid>
            <NotificationSnackbar />
        </section>
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addDraft,
            toggleNotification,
            notificationMessage,
            fieldTypes,
        },
        dispatch
    );

const mapStateToProps = state => ({
    user: state.user,
    draft: state.draft,
    test_details_categories: state.test_details_categories,
    draftName: state.draftName,
    test_field_types: state.test_field_types,

});

export default connect(mapStateToProps, mapDispatchToProps)(RenderTestWithSideMenu);