import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles"
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import AdminPanelSettingsIcon from '@material-ui/icons/SettingsApplicationsSharp';
import { withStyles } from '@material-ui/core/styles';
import EnterDraftNameModal from '../components/EnterDraftNameModal';
import PendingChanges from './pending_tests_components/pendingChanges'
import ViewDrafts from '../components/ViewDrafts';
import { getNumberOfDrafts, checkAdminAccess } from "http_calls/userHttp"
import Badge from '@material-ui/core/Badge'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  loggingUser,
  toggleNotification,
  notificationMessage
} from "actions/TestActions";
import AdminPanel from "./adminPanel/adminPanel";
import EditFieldPanel from "./editFieldTypes/editFieldTypes";
import BuildCircle from "@material-ui/icons/BuildSharp"
import UploadIcon from "@material-ui/icons/CloudUpload";
import UploadForm from  "./upload_docs/upload_form"
import EditLabSectionDialog from "./editLabAndSections/editDialog";
import TestReports from "./test_reports_components/TestReports";



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft:'-2vw',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

function ProfileMenu(props) {

  const [draftModalOpen, setDraftModalOpen] = React.useState(false);
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const [openViewDrafts, setViewOpenDrafts] = useState(false);
  const [draftsCount, setDraftCount] = useState(0);
  const [adminAccess, setAdminAccess] = useState(false);
  const [openAdminPanel, setOpenAdminPanel] = useState(false);
  const [openReportsPanel,setOpenReportsPanel] = useState(false);
  const [openEditFields,setEditFields] = useState(false);
 const [openEditLabsAndSections,setOPenLabsAndSections] = useState(false);
  const [openUpload, setOpenUplaod] = useState(false);
  const classes = useStyles();
  const { open } = props;
  useEffect(() => {
    getNumberOfDrafts().then(res => {
      if (res.IsError === false) {
        setDraftCount(res.Data)
      } else {
        props.toggleNotification(res.IsError)
        if (props.notifyMsg !== res.Msg) {
          props.notificationMessage(res.Msg)
        }
      }
    })
    checkAdminAccess().then(res => {
      setAdminAccess(res.data.access)
    }).catch(err => { console.log(err) })
  }, [props.notifyMsg, props.open])
  function handleOpenUserProfile() {
    setOpenUserProfile(true);
  }
  function handleCloseUserProfile() {
    setOpenUserProfile(false)
  }
  function handleViewDrafts() {
    setViewOpenDrafts(true)
  }
  function closeViewDrafts() {
    setViewOpenDrafts(false)
  }
  function isDisabled() {
    if (draftsCount < 1) {
      return true
    }
    return false;
  }
  function closeAdminPanel() {
    setOpenAdminPanel(false)
  }
  function closeEditFieldPanel(){
    setEditFields(false)
  }

  function handleUploadOpen(){
    setOpenUplaod(true)
  }
  function handleUploadClose(){
    setOpenUplaod(false)
  }

  const handleDraftModalClose = () => {
    setDraftModalOpen(false)
  }

  const handleLabsAndSectionsClose = ()=>{
    setOPenLabsAndSections(false)
  }
  const handleClose= (e)=>{
  
    props.close();
  }
  const onReviewClick=()=>{
    window.open("/review","_blank")
  }

  const onReportClick = () =>{
    window.open("/reports/view","_blank")
  }

  return (
    <div className={classes.root}>
      <EnterDraftNameModal open={draftModalOpen} handleClose={handleDraftModalClose} />
      {open && ( <Popper open={open}  transition keepMounted disablePortal placement={"right-end"}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {adminAccess ? <MenuItem onClick={() => { setOpenAdminPanel(true) }}><AdminPanelSettingsIcon /> Admin panel</MenuItem> : <span />}
                  {adminAccess ? <MenuItem onClick={() => { setEditFields(true) }}><BuildCircle /> Edit Field Types</MenuItem> : <span />}
                  {adminAccess ? <MenuItem onClick={()=>{setOPenLabsAndSections(true)} }> {"Edit Labs & Sections"} </MenuItem>:<span/>}
                  {adminAccess ? <MenuItem onClick={()=>{setOpenReportsPanel(true)}} >{"Test Reports"}</MenuItem>:<span/>}
                   <MenuItem onClick={() => { setOpenUplaod(true) }}><UploadIcon/>{"Upload & View"}</MenuItem>
                   <MenuItem onClick={onReviewClick} > Review Tests</MenuItem>
                  < Divider />
                  <MenuItem onClick={() => { setDraftModalOpen(!draftModalOpen) }} >Add new Test</MenuItem>
                  <MenuItem onClick={handleViewDrafts} disabled={isDisabled()}><Badge className={classes.margin} badgeContent={draftsCount} color="primary">
                    Saved Drafts
                  </Badge></MenuItem>
                  <MenuItem onClick={handleOpenUserProfile}>Pending Changes</MenuItem>
                  <MenuItem onClick={props.logout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>)}
     
      <AdminPanel handleClose={closeAdminPanel} open={openAdminPanel} />
      <EditFieldPanel handleClose={closeEditFieldPanel} open={openEditFields}/>
      <ViewDrafts onClose={closeViewDrafts} open={openViewDrafts} handleOpen={handleViewDrafts} />
      <PendingChanges open={openUserProfile} handleOpen={handleOpenUserProfile} handleClose={handleCloseUserProfile} />
      <UploadForm open={openUpload} handleOpen={handleUploadOpen} handleClose={handleUploadClose}/>
      <EditLabSectionDialog open={openEditLabsAndSections} handleOpen={()=>{setOPenLabsAndSections(true)}} handleClose={()=>{setOPenLabsAndSections(false)}}/>
      <TestReports open={openReportsPanel} handleOpen={()=>{setOpenReportsPanel(true)}} handleClose={()=>{setOpenReportsPanel(false)}}/>
    </div>
  );
}


ProfileMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  notificationOpen: state.notificationOpen,
  notifyMsg: state.notificationMessage
})
const mapDispatchtoProps = dispatch => {
  return bindActionCreators(
    { toggleNotification, notificationMessage }, dispatch
  )
}
export default connect(mapStateToProps, mapDispatchtoProps)(ProfileMenu);
