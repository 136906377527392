import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";
import {
    Card,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    Button,
    Grid,
    Grow,
    Avatar,
    useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import ExpandMore from "@material-ui/icons/ExpandMore"
import ExpandLess from "@material-ui/icons/ExpandLess"
import { makeStyles } from "@material-ui/core/styles"


const useStyles = makeStyles(theme => {
    // const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return {
        root: {
            padding: theme.spacing(3, 2),
        },
        [theme.breakpoints.down('md')]: {
            // Style applied when viewport is above 'md'
            card:{
                width: "80vw",
            }
          },
          [theme.breakpoints.up('md')]: {
            // Style applied when viewport is above 'md'
            card:{
                width: "48vw",
            }
          },
        /* Styles applied to the root element if `square={false}`. */
        rounded: {
            borderRadius: 0,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
        },
        /* Styles applied to the root element if `expanded={true}`. */
        expanded: {},
        /* Styles applied to the root element if `disabled={true}`. */
        disabled: {},
        cardContent:{
            padding:"0px 0px",
            '&:last-child':{
                paddingBottom:"0px"
            }
        },
        Box: {
            padding: theme.spacing(1, 1),

        }

    };

})

function ExpansionPanel(props) {
    const classes = useStyles()
    const [expand, setExpanded] = useState(false);

    useEffect(()=>{
        setExpanded(false)
    },[props.children])

    const handleExpand = () => {
        console.log("EXPANDED ... ", expand)
        setExpanded(true)
    }
    const handleLessExpand = () => {
        console.log("DE EXPANDED ... ", expand)
        setExpanded(false)
    }
    const getExpandMoreButton = () => {
        return (
            <IconButton onlick={handleExpand}>
                <ExpandMore />
            </IconButton>
        )
    }

    const toggleExpand= ()=>{
        setExpanded(!expand)
    }

    const getExpandLessButton = () => {
        return (
            <IconButton onClick={handleLessExpand}>
                <ExpandLess />
            </IconButton>
        )
    }
    return (
        <div>
           
                <Card className={classes.card}>
                    <CardHeader                         
                         
                        action={
                            <IconButton size="medium" onClick={toggleExpand}>
                                   {expand ? <ExpandLess /> : <ExpandMore/>}
                            </IconButton>} 
                         title={CardTitle(props) } 
                         />
                    <CardContent className={classes.cardContent}>
                        <Collapse in={expand}>
                            { expand?props.children:""}
                        </Collapse>
                    </CardContent>
                </Card>
          
        </div>

    )
}


function CardTitle(props) {
    return (
        <Grid container direction="row" alignItems="center">
            <Grid item>
                {props.header}
            </Grid>
            <Grid item>
                {props.openLinkAction}
            </Grid>
        </Grid>
    )

}

ExpansionPanel.propTypes = {
    /**
     * If `true`, expands the panel by default.
     */
    defaultExpanded: PropTypes.bool,
    /**
     * If `true`, the panel will be displayed in a disabled state.
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, expands the panel, otherwise collapse it.
     * Setting this prop enables control over the panel.
     */
    expanded: PropTypes.bool,
    /**
     * Callback fired when the expand/collapse state is changed.
     *
     * @param {object} event The event source of the callback
     * @param {boolean} expanded The `expanded` state of the panel
     */
    onChange: PropTypes.func,
    /**
     * @ignore
     */
    square: PropTypes.bool,
    /**
     * The component used for the collapse effect.
     */
    TransitionComponent: PropTypes.elementType,
    /**
     * Properties applied to the `Transition` element.
     */
    TransitionProps: PropTypes.object,


};

export default ExpansionPanel