import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@material-ui/core/CircularProgress';
import { submitUserCredentials } from "../../../httpCalls/userHttp";
import { bindActionCreators } from "redux";
import {
	loggingUser,
	toggleNotification,
	notificationMessage
} from "../../actions/TestActions";
import { connect } from "react-redux";
import BroadcastChannel from "broadcast-channel";
import jwt from "jsonwebtoken";

const styles = theme => ({
	margin: {
		margin: theme.spacing(1)
	},
	buttonSuccess: {
		backgroundColor: theme.palette.success[500],
		'&:hover': {
			backgroundColor: theme.palette.success[500],
		},
	},
	buttonProgress: {
		color: theme.palette.success[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
});

const defaultValues = {
	username: "",
	password: "",
}

function LoginForm(props) {


	const [open, setOpen] = useState(false);
	const { register, handleSubmit, control, reset, errors } = useForm({ defaultValues });
	const [showPassword, setShowPassword] = useState(false);
	const [loginDetails, setLoginDetails] = useState({
		username: "",
		password: ""
	});

	const [loading, setLoading] = useState(false)

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}
	useEffect(() => {
		if (props.user !== undefined && loading === true) {
			setLoading(false)

			handleClose();
		}
	}, [props.user]);

	function handleClickShowPassword() {
		setShowPassword(!showPassword);
	}
	function handleUsernameChange(event) {
		setUsername(event.target.value);
	}
	function handlePasswordChange(event) {
		setPassword(event.target.value);
	}

	function onSubmit(data) {
		setLoading(true)
		event.preventDefault();
		if (!loading) {
			submitUserCredentials({
				username: data.username,
				password: data.password
			}).then(result => {
				if (result.IsError === false) {
					props.loggingUser(result.Data)
				} else {
					reset();
				}
				props.notificationMessage(result.Msg)
			}).catch(err => {
				console.error(err);
				reset();
			});
		}
		event.stopPropagation();
	}

	const { classes } = props;
	return (
		<div>
			<Button variant="contained" color="secondary" onClick={handleClickOpen}>
				Login
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title">
				<form method="post" onSubmit={handleSubmit(onSubmit)} id="login-form">
					<DialogTitle id="form-dialog-title">
						Please enter your unique name and password.
					</DialogTitle>
					<DialogContent>
						<Controller
							name="username"
							control={control}
							rules={{ required: true }}
							render={({ field, fieldState }) => (
								<TextField
									autoFocus
									error={!!fieldState.error}
									helperText={fieldState.error ? "Invalid username" : ""}
									className={classNames(classes.margin)}
									id="uname-login-form"
									label="Unique Name"
									type="text"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<AccountCircle />
											</InputAdornment>
										),
										autoComplete: "username"
									}}
									{...field}
								/>
							)}
						/>
						<Controller
							name="password"
							control={control}
							rules={{ required: true, minLength: 2 }}
							render={({ field, fieldState }) => (
								<TextField
									id="filled-adornment-password"
									className={classNames(classes.margin)}
									type={showPassword ? "text" : "password"}
									error={!!fieldState.error}
									helperText={fieldState.error ? "Invalid password" : ""}
									label="Password"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Toggle password visibility"
													onClick={handleClickShowPassword}>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
										autoComplete: "current-password"
									}}
									{...field}
								/>
							)}
						/>


					</DialogContent>
					<DialogActions>
						<Button type="submit" disabled={loading} color="primary" onClick={handleSubmit(onSubmit)}>
							{loading && <CircularProgress size={24} color={"secondary"} />}
							sign in
						</Button>

					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
}

LoginForm.propTypes = {
	classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	notificationOpen: state.notificationOpen,
	user: state.user
});
const mapDispatchProps = dispatch => {
	return bindActionCreators(
		{ loggingUser, toggleNotification, notificationMessage },
		dispatch
	);
};

const LoginFormWithStyles = withStyles(styles)(LoginForm);
export default connect(
	mapStateToProps,
	mapDispatchProps
)(LoginFormWithStyles);
