import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'


export default function RenderSideMenu(props) {
    if (props.text !== "All") {
        return (<ListItem button key={props.text} onClick={props.onClick} selected={props.selected}>
            <ListItemIcon>
                {props.icon}
            </ListItemIcon>
            <ListItemText primary={props.text} />
        </ListItem>)
    } else {
        return <span />
    }

}